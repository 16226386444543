import React from "react";
import style from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../store/actions";

const HeaderWrapper = style.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = style(Link)`
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  text-decoration: none;
  color: #000;
  margin: 40px 0;
`;

const HeaderLink = style(Link)`
  text-align: center;
  font-size: 16px;
  color: #000;
  opacity: .5;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`;

const HeaderIcon = style.svg`
  width: 24px;
  height: 24px;
  color: #F14946;
  margin-right: 5px;
`;

const Header = props => {

    return (
      <>
        <HeaderWrapper>
          <HeaderLink to="/about">
            What is this?
          </HeaderLink>
          <Title to="/">Art Diario</Title>
          <HeaderLink to="/download">
            <HeaderIcon xmlns="http://www.w3.org/2000/svg" className="header-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </HeaderIcon>
            <span>Download</span>
          </HeaderLink>
        </HeaderWrapper>
      </>
    );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(withRouter(Header));