import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  GET_ART_START,
  GET_ART_SUCCESS,
  GET_ART_ERROR,
  GET_ALL_ART_START,
  GET_ALL_ART_SUCCESS,
  GET_ALL_ART_ERROR,
  GET_ARTBYDAY_START,
  GET_ARTBYDAY_SUCCESS,
  GET_ARTBYDAY_ERROR,
  POST_ART_START,
  POST_ART_SUCCESS,
  POST_ART_ERROR,
  DELETE_ART_START,
  DELETE_ART_SUCCESS,
  DELETE_ART_ERROR,
  LOGOUT,
} from "../actions";

const initialState = {
  username: "",
  password: "",
  loginStart: false,
  loginSuccess: false,
  loginError: false,
  isLoggedIn: false,
  token: "",
  getArtStart: false,
  getArtSuccess: false,
  getArtError: false,
  getAllArtStart: false,
  getAllArtSuccess: false,
  getAllArtError: false,
  getArtByDayStart: false,
  getArtByDaySuccess: false,
  getArtByDayError: false,
  postArtStart: false,
  postArtSuccess: false,
  postArtError: false,
  deleteArtStart: false,
  deleteArtSuccess: false,
  deleteArtError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loginStart: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginStart: false,
        loginSuccess: true,
        isLoggedIn: true,
        signupNew: false,
        token: action.payload,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loginStart: false,
        loginError: true,
      };

    case GET_ART_START:
      return {
        ...state,
        getArtStart: true,
      };

    case GET_ART_SUCCESS:
      return {
        ...state,
        getArtStart: false,
        getArtError: false,
        artList: action.payload,
      };

    case GET_ART_ERROR:
      return {
        ...state,
        getArtStart: false,
        getArtError: true,
      };

    case GET_ALL_ART_START:
      return {
        ...state,
        getAllArtStart: true,
      };

    case GET_ALL_ART_SUCCESS:
      return {
        ...state,
        getAllArtStart: false,
        getAllArtError: false,
        allArtList: action.payload,
      };

    case GET_ALL_ART_ERROR:
      return {
        ...state,
        getAllArtStart: false,
        getAllArtError: true,
      };

    case GET_ARTBYDAY_START:
      return {
        ...state,
        getArtByDayStart: true,
      };

    case GET_ARTBYDAY_SUCCESS:
      return {
        ...state,
        getArtByDayStart: false,
        getArtByDaySuccess: true,
        getArtByDayError: false,
        artOfTheDay: action.payload,
      };

    case GET_ARTBYDAY_ERROR:
      return {
        ...state,
        getArtByDayStart: false,
        getArtByDaySuccess: false,
        getArtByDayError: true,
      };

    case POST_ART_START:
      return {
        ...state,
        postArtStart: true,
      };

    case POST_ART_SUCCESS:
      return {
        ...state,
        postArtStart: false,
        postArtError: false,
      };

    case POST_ART_ERROR:
      return {
        ...state,
        postArtStart: false,
        postArtError: true,
      };

    case DELETE_ART_START:
      return {
        ...state,
        deleteArtStart: true,
      };

    case DELETE_ART_SUCCESS:
      return {
        ...state,
        deleteArtStart: false,
        deleteArtError: false,
      };

    case DELETE_ART_ERROR:
      return {
        ...state,
        deleteArtStart: false,
        deleteArtError: true,
      };

    case LOGOUT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
