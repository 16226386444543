import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login } from "../store/actions";
import style from "styled-components";

const Form = style.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FormFields = style.div`
  width: 25%;
  margin: 0 auto;
  margin-top: 20%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FormInput = style.input`
  border: none;
  display: block;
  border-bottom: 2px solid #000;
  padding: 10px 0;
  width: 100%;
  outline: none;
  margin-bottom: 20px;
  &:focus {
    border-bottom: 2px solid #F14946;
  }
`;

const FormLabel = style.label`
  opacity: .5;
  font-weight: 700;
  font-size: 12px;
  display: block;
  width: 100%;
`;

const SubmitButton = style.button`
  background-color: #000;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  font-weight: 800;
  transition: all .2s ease-in-out;
  cursor: pointer;
  &:disabled {
    opacity: .3;
  }
  &:hover {
    background-color: #F14946;
  }
`;

function Login(props) {
  const [creds, setCreds] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.login(creds, props.history);
  };

  return (
    <>
      <FormFields>
        <Form onSubmit={onSubmit}>
          <h1>Art Diario</h1>
          <FormLabel>email</FormLabel>
          <FormInput
            required
            type="text"
            name="email"
            value={creds.email}
            onChange={handleChange}
          ></FormInput>
          <FormLabel>Password</FormLabel>
          <FormInput
            required
            type="password"
            name="password"
            value={creds.password}
            onChange={handleChange}
          ></FormInput>
          {props.loginStart ? (
            <SubmitButton type="submit" disabled>
              Loading...
            </SubmitButton>
          ) : (
            <SubmitButton type="submit">Login</SubmitButton>
          )}
          {props.loginError && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              There has been an issue logging in. Please check your credentials.
            </p>
          )}
        </Form>
      </FormFields>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginStart: state.loginStart,
  loginError: state.loginError,
  isLoggedIn: state.isLoggedIn,
  token: state.token
});

export default connect(mapStateToProps, { login })(withRouter(Login));