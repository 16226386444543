import React, { useState } from "react";
import style from "styled-components";
import moment from "moment";
import FadeIn from "react-fade-in";

const Art = style.div`
  background: #0b0c0d;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,.4);
  margin-bottom: 40px;
`;

const Image = style.img`
  width: 100%;
`;

const ArtContent = style.div`
  padding: ${(props) => (props.artPage ? "10px 15px" : "20px 30px")};
`;

const Artist = style.h2`
  color: #fff;
  font-size: ${(props) => (props.artPage ? "18px" : "24px")};
  opacity: .9;
  margin: 5px 0;
`;

const Title = style.h3`
  color: #fff;
  opacity: .7;
  font-size: ${(props) => (props.artPage ? "16px" : "18px")};
  margin: 5px 0;
`;

const DateToShow = style.h4`
  color: #F14946;
  margin: 5px 0;
  font-size: ${(props) => (props.artPage ? "14px" : "18px")};
`;

const Description = style.p`
  color: #fff;
  opacity: .8;
  font-size: ${(props) => (props.artPage ? "14px" : "16px")};
`;

const ExternalLink = style.a`
  color: #F14946;
  opacity: .8;
  font-weight: 700;
  text-decoration: none;
  display: ${(props) => (props.artPage ? "none" : "block")};
  &:hover {
    text-decoration: underline;
  }
`;

const ArtPiece = (props) => {
  const [loaded, setLoaded] = useState(false);
  // console.log(props);
  // if (props.artPage) {
  //   console.log(props.artPage);
  // }

  return (
    <>
      {loaded != null ? (
        <FadeIn>
          <Art key={props.id}>
            <Image src={props.imageUrl} onLoad={() => setLoaded(true)} />
            <ArtContent artPage={props.artPage ? true : false}>
              <Artist artPage={props.artPage ? true : false}>
                {props.artistName}
              </Artist>
              <Title artPage={props.artPage ? true : false}>
                {props.artTitle}
              </Title>
              {props.today ? (
                ""
              ) : (
                <DateToShow artPage={props.artPage ? true : false}>
                  {moment.utc(props.dateToShow).format("MM-DD-YYYY")}
                </DateToShow>
              )}
              {props.showLink ? (
                <ExternalLink
                  href={props.externalLink}
                  target="_blank"
                  artPage={props.artPage ? true : false}
                >
                  Find Out More
                </ExternalLink>
              ) : (
                ""
              )}

              <Description artPage={props.artPage ? true : false}>
                {props.artDescription}
              </Description>
            </ArtContent>
          </Art>
        </FadeIn>
      ) : (
        ""
      )}
    </>
  );
};

export default ArtPiece;
