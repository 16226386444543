import React from "react";
import style from "styled-components";

const Button = style.button`
    background-color: #000;
    border: none;
    color: #fff;
    font-size: 18px;
    padding: 10px 15px;
    font-weight: 800;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:disabled {
        opacity: .3;
        cursor: not-allowed;
    }
    &:hover {
        background-color: #F14946;
    }
`;

export const SquareButton = ({ children, ...props }) => (
    <>
        {props.disabled ? (
        <Button {...props} type="submit" disabled>
            {children}
        </Button>
        ) : (
        <Button {...props} type="submit">{children}</Button>
        )}
    </>
);