import { axiosWithAuth } from "../../utils/axiosWithAuth";

const urlServer = process.env.REACT_APP_API;
// const urlDs = "http://localhost:5500/api";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const login = (creds, history) => (dispatch) => {
  dispatch({ type: LOGIN_START });
  axiosWithAuth()
    .post(`${urlServer}/auth/login`, creds)
    .then((res) => {
      setTimeout(() => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.user_id);
        setTimeout(() => {
          dispatch({ type: LOGIN_SUCCESS, payload: res.data.token });
          history.push("/art");
        }, 100);
      }, 100);
    })
    .catch((err) => {
      dispatch({ type: LOGIN_ERROR });
      console.log(err);
    });
};

export const GET_ART_START = "GET_ART_START";
export const GET_ART_SUCCESS = "GET_ART_SUCCESS";
export const GET_ART_ERROR = "GET_ART_ERROR";

export const getArt = (token, today) => (dispatch) => {
  dispatch({ type: GET_ART_START });
  axiosWithAuth(token)
    .get(`${urlServer}/art/future/${today}`)
    .then((res) => {
      // console.log(res.data);
      setTimeout(() => {
        dispatch({ type: GET_ART_SUCCESS, payload: res.data });
      }, 100);
    })
    .catch((err) => {
      dispatch({ type: GET_ART_ERROR });
      console.log(err);
    });
};

export const GET_ALL_ART_START = "GET_ALL_ART_START";
export const GET_ALL_ART_SUCCESS = "GET_ALL_ART_SUCCESS";
export const GET_ALL_ART_ERROR = "GET_ALL_ART_ERROR";

export const getAllArt = (token) => (dispatch) => {
  dispatch({ type: GET_ART_START });
  axiosWithAuth(token)
    .get(`${urlServer}/art/all`)
    .then((res) => {
      setTimeout(() => {
        dispatch({ type: GET_ALL_ART_SUCCESS, payload: res.data });
      }, 100);
    })
    .catch((err) => {
      dispatch({ type: GET_ALL_ART_ERROR });
      console.log(err);
    });
};

export const GET_ARTBYDAY_START = "GET_ARTBYDAY_START";
export const GET_ARTBYDAY_SUCCESS = "GET_ARTBYDAY_SUCCESS";
export const GET_ARTBYDAY_ERROR = "GET_ARTBYDAY_ERROR";

export const getArtByDay = (date) => (dispatch) => {
  dispatch({ type: GET_ARTBYDAY_START });
  axiosWithAuth()
    .get(`${urlServer}/art/${date}`)
    .then((res) => {
      setTimeout(() => {
        dispatch({ type: GET_ARTBYDAY_SUCCESS, payload: res.data[0] });
      }, 200);
    })
    .catch((err) => {
      dispatch({ type: GET_ARTBYDAY_ERROR });
      console.log(err);
    });
};

export const POST_ART_START = "POST_ART_START";
export const POST_ART_SUCCESS = "POST_ART_SUCCESS";
export const POST_ART_ERROR = "POST_ART_ERROR";

export const postArt = (token, art, history) => (dispatch) => {
  dispatch({ type: POST_ART_START });
  axiosWithAuth(token)
    .post(`${urlServer}/art`, art)
    .then((res) => {
      setTimeout(() => {
        dispatch({ type: POST_ART_SUCCESS });
        history.push(`/art`);
      }, 200);
    })
    .catch((err) => dispatch({ type: POST_ART_ERROR, payload: err }));
};

export const DELETE_ART_START = "DELETE_ART_START";
export const DELETE_ART_SUCCESS = "DELETE_ART_SUCCESS";
export const DELETE_ART_ERROR = "DELETE_ART_ERROR";

export const deleteArt = (token, artId, history) => (dispatch) => {
  console.log(artId);
  dispatch({ type: DELETE_ART_START });
  axiosWithAuth(token)
    .delete(`${urlServer}/art/${artId}`)
    .then((res) => {
      setTimeout(() => {
        dispatch({ type: DELETE_ART_SUCCESS });
        dispatch(getAllArt(token));
        history.push(`/art`);
      }, 200);
    })
    .catch((err) => dispatch({ type: DELETE_ART_ERROR, payload: err }));
};

export const LOGOUT = "LOGOUT";

export const logout = (history) => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  history.push("/login");
};
