import React from "react";
import PrivateRoute from "./components/PrivateRoute";
import { logout } from "./store/actions";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import TagManager from "react-gtm-module";
import Login from "./components/Login";
import Art from "./components/Art.js";
import About from "./components/About.js";
import AddArt from "./components/AddArt.js";
import Download from "./components/Download.js";
import ArtByDate from "./components/ArtByDate.js";
import style from "styled-components";

const Container = style.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const gtmUserId = `${uuidv4()}`;
if (localStorage.getItem("gtmUserId")) {
    const tagManagerArgs = {
      gtmId: "GTM-W8W2TVB",
      dataLayer: {
        userId: localStorage.getItem("gtmUserId"),
      },
    };
    TagManager.initialize(tagManagerArgs);
} else {
    const tagManagerArgs = {
      gtmId: "GTM-W8W2TVB",
      dataLayer: {
        userId: gtmUserId,
      },
    };
    TagManager.initialize(tagManagerArgs);
}

function App(props) {
  return (
    <Router>
      <Switch>
        <Container>
          <PrivateRoute
            path="/art"
            exact
            component={localStorage.getItem("token") ? Art : Login}
          />
          <PrivateRoute
            path="/art/add"
            exact
            component={localStorage.getItem("token") ? AddArt : Login}
          />
          <Route path="/about" component={About} />
          <Route path="/login" component={Login} />
          <Route path="/download" component={Download} />
          <Route path="/art/date/:date" component={ArtByDate} />
          <Route exact path="/" component={ArtByDate} />
        </Container>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  loginStart: state.loginStart,
  token: state.token,
});

export default connect(mapStateToProps, { logout })(withRouter(App));
