import React from "react";
import style from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../store/actions";

const Footer = style.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 40px;
`;

const Logout = style.a`
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  opacity: .5;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const FooterLinkInternal = style(Link)`
  text-align: center;
  font-size: 16px;
  color: #000;
  opacity: .5;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const Header = (props) => {
  const logoutButton = (e, property) => {
    e.stopPropagation();
    props.logout(props.history);
  };

  return (
    <>
      <Footer>
        <Link to="/"><img alt="Footer Logo" src={process.env.PUBLIC_URL + "/logo.svg"} /></Link>
        <FooterLinkInternal to="/about">About</FooterLinkInternal>
        {localStorage.getItem("token") ? (
          <>
            <FooterLinkInternal to="/art/add">Add +</FooterLinkInternal>
            <FooterLinkInternal to="/art">Art Calendar</FooterLinkInternal>
            <Logout onClick={(e) => logoutButton(e)}>Logout</Logout>
          </>
        ) : (
          console.log("Not logged in")
        )}
      </Footer>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(withRouter(Header));
