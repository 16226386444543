import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { postArt, getAllArt } from "../store/actions";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { axiosWithAuth } from "../utils/axiosWithAuth";
import ReactCrop from "react-image-crop";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";
// eslint-disable-next-line
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import moment from "moment";
import style from "styled-components";
// import { upload } from "superagent";

// run an axios call to get a pre-signed url when someone drops an image, maybe handling it with useEffect?
// Once you have the URL, then PUT the image to the URL via the useS3Upload component

const pica = require("pica")();

const Divider = style.hr`
  border: 0;
  border-top: 1px solid #E2E2E2;
  width: 80%;
`;

const ArtForm = style.form`
  display: flex;
  flex: 1;
  padding: 50px 0;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
  }
`;

const FormFields = style.div`
  width: 50%;
  margin-left: 50px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: unset;
  }
`;

const FormInput = style.input`
  border: none;
  display: block;
  border-bottom: 2px solid #000;
  padding: 10px 0;
  width: 100%;
  outline: none;
  margin-bottom: 20px;
  &:focus {
    border-bottom: 2px solid #F14946;
  }
`;

const FormError = style.p`
  width: 100%;
  color: #E31A16;
  font-weight: 600;
  font-size: 14px;
`;

const FormLabel = style.label`
  opacity: .5;
  font-weight: 700;
  font-size: 12px;
  display: block;
  width: 100%;
`;

const SubmitButton = style.button`
  background-color: #000;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  font-weight: 800;
  cursor: pointer;
  margin-right: 5px;
  &:disabled {
    opacity: .3;
  }
  &:hover {
    background-color: #F14946;
  }
`;

const DateError = style.p`
  color: #E31A16;
  font-weight: 600;
  font-size: 14px;
  margin: 0 auto;
`;

const DatePickerWrapper = style.div`
  display: flex;
  &&& {
    input {
      border: none;
      outline: none;
      border-bottom: 2px solid #000;
      padding: 10px 0;
      width: 100%;
      margin-bottom: 20px;
    }

    .DayPicker-TodayButton {
      width: 100%;
      text-align: center;
    }

    .DayPicker-Day--disabled {
      color: #dce0e0;
      cursor: default;
    }
`;

// Drop Area

const DropArea = style.div`
  width: 100%;
  height: 100%;   
  border-radius: 10px;
  display: flex;
  border: 2px dotted #E3E3E3;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
`;

const DropAreaText = style.p`
  font-weight: 700;
  opacity: .5;
  margin: auto;
  text-align: center;
`;

const ArtPreview = style.aside`
  background: #0b0c0d;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,.4);
  margin-bottom: 40px;
  canvas {
    width: 100%;
  }
`;

const ArtArea = style.div`
  width: 70%;
`;

const ArtContent = style.div`
  padding: 20px 30px;
`;

const Artist = style.h2`
  color: #fff;
  font-size: 24px;
  opacity: 0.9;
  margin: 5px 0px;
`;

const Title = style.h3`
  color: #fff;
  opacity: 0.7;
  font-size: 18px;
  margin: 5px 0px;
`;

const DateToShow = style.h4`
  color: #F14946;
`;

const Description = style.p`
  color: #fff;
  opacity: .8;
  margin: 5px 0px;
`;

const generateDownload = (canvas, crop, mimetype) => {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = "cropPreview";
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    mimetype,
    1
  );
};

const AddArt = (props) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  const [art, setArt] = useState({
    artistName: "",
    artTitle: "",
    dateArtCreated: "",
    dateToShow:
      query.get("date") && query.get("date").length > 0
        ? moment(query.get("date")).format("YYYY-MM-DD")
        : "",
    artDescription: "",
    externalLink: "",
    imageUrl: "",
    user_id: parseInt(localStorage.getItem("user_id")),
  });

  const [dateError, setDateError] = useState("");

  useEffect(() => {
    // console.log(`${process.env.REACT_APP_API}/art/${art.dateToShow}`);
    axios
      .get(`${process.env.REACT_APP_API}/art/${art.dateToShow}`)
      .then((res) => {
        console.log(`date is taken, ${res.data}`);
        setDateError("Date is Taken, try another!");
      })
      .catch((err) => {
        setDateError("");
      });
  }, [art.dateToShow]);

  const handleChanges = (event) => {
    setArt({ ...art, [event.target.name]: event.target.value });
  };

  // On submit, I want to:
  // Post the art to S3, both original and cropped
  // Can I get a thumbnail? Fuck that'd be nice
  // console.log(`${fileDetails.name}-cropped.${fileDetails.extension}`);

  // Need to adjust this to change name for each file.
  const uploadToS3 = (file, type) => {
    axiosWithAuth(localStorage.getItem("token"))
      .post(ApiGatewayUrl, {
        fileType: fileDetails.mimeType,
        fileName: `${fileDetails.name}-${type}.${fileDetails.extension}`,
      })
      .then((response) => {
        const options = {
          headers: { Content: fileDetails.mimeType },
        };
        axios
          .put(response.data.presignedUploadUrl, file, options)
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitForm = (event) => {
    event.preventDefault();

    for (const [key, value] of Object.entries(s3Files)) {
      uploadToS3(value, key);
    }

    props.postArt(localStorage.getItem("token"), art, props.history);
  };

  // Art Dropping/Cropping

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 10 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [file, setFile] = useState({});
  const [s3Files, setS3Files] = useState({
    original: "",
    cropped: "",
    thumbnail: "",
    resized: "",
  });

  const [fileDetails, setFileDetails] = useState({
    name: "",
    extension: "",
    mimeType: "",
  });

  const ApiGatewayUrl = process.env.REACT_APP_PRESIGNED_ENDPOINT;
  const key = `${uuidv4()}`;

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 20000000,
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      const fileExt = acceptedFiles[0].name.split(".").pop().toLowerCase();

      if (fileExt === "png") {
        setFileDetails({
          ...fileDetails,
          name: key,
          extension: fileExt,
          mimeType: "image/png",
        });
        console.log(fileDetails);
      } else if (fileExt === "jpg" || fileExt === "jpeg") {
        setFileDetails({
          ...fileDetails,
          name: key,
          extension: fileExt,
          mimeType: "image/jpeg",
        });
        console.log(fileDetails);
      }

      window.URL.revokeObjectURL(newFile.preview);

      const fileWithPreview = {
        ...newFile,
        preview: URL.createObjectURL(newFile, { type: fileDetails.mimeType }),
      };

      setS3Files({ ...s3Files, original: newFile });
      setFile(fileWithPreview);

      setArt({
        ...art,
        imageUrl: `https://media.artdiario.com/art/${key}-original.${fileExt}`,
      });
    },
  });

  // Create canvas with just width initially

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // wait until we have both mimetype and the file to create a thumbnail with
  useEffect(() => {
    if (fileDetails.mimeType && imgRef.current) {
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.id = "thumbnail";
      resizedCanvas.width = 600;
      resizedCanvas.height =
        imgRef.current.naturalHeight / (imgRef.current.naturalWidth / 600);

      pica
        .resize(imgRef.current, resizedCanvas)
        .then((result) => pica.toBlob(result, fileDetails.mimeType, 0.9))
        .then((blob) => setS3Files({ ...s3Files, thumbnail: blob }))
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgRef.current]);

  // wait until we have both mimetype and the file to create a thumbnail with
  useEffect(() => {
    if (fileDetails.mimeType && imgRef.current && s3Files.thumbnail) {
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.id = "resized";
      resizedCanvas.width = 1200;
      resizedCanvas.height =
        imgRef.current.naturalHeight / (imgRef.current.naturalWidth / 1200);

      pica
        .resize(imgRef.current, resizedCanvas)
        .then((result) => pica.toBlob(result, fileDetails.mimeType, 0.9))
        .then((blob) => setS3Files({ ...s3Files, resized: blob }))
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgRef.current, s3Files.thumbnail]);

  // wait until we have both mimetype and the file to resize the image to 1200px
  // useEffect(() => {
  //   if (fileDetails.mimeType && imgRef.current) {
  //     const resizedCanvas = document.createElement("canvas");
  //     resizedCanvas.width = 1200;
  //     resizedCanvas.height =
  //       imgRef.current.naturalHeight / (imgRef.current.naturalWidth / 1200);

  //     pica
  //       .resize(imgRef.current, resizedCanvas)
  //       .then((result) => pica.toBlob(result, fileDetails.mimeType, 0.9))
  //       .then((blob) => setS3Files({ ...s3Files, resized: blob }));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [imgRef.current]);

  // Flow
  // Drop image
  // Set name/key, mimetype, extension
  // Set preview blob
  // Set art URL for the database

  // Onload, set imgRef.current to blob
  // UseEffect for when a completedCrop has changed, create a new crop
  // Assign that crop to a state

  useEffect(() => {
    if (
      completedCrop &&
      imgRef.current &&
      s3Files.thumbnail &&
      s3Files.resized
    ) {
      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const ctx = canvas.getContext("2d");
      // const pixelRatio = window.devicePixelRatio;
      const pixelRatio = 1;

      // canvas.width = crop.width * pixelRatio * scaleX;
      // canvas.height = crop.height * pixelRatio * scaleY;
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      // console.log(
      //   `canvas width: ${canvas.width}, crop width: ${crop.width}, pixel ratio: ${pixelRatio}, scaleX: ${scaleX}`
      // );

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      pica
        .toBlob(canvas, fileDetails.mimeType, 0.9)
        .then((blob) =>
          setS3Files((s3Files) => ({ ...s3Files, cropped: blob }))
        )
        .then(console.log(s3Files));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, s3Files.thumbnail, s3Files.resized]);

  const imageReset = () => {
    imgRef.current = null;
    setFile({});
    setCompletedCrop(null);
    setS3Files({});
  };

  const [disabledDates, setDisabledDates] = useState([]);

  useEffect(() => {
    if (props.allArtList && props.allArtList.length) {
      props.allArtList.map((item) => {
        return setDisabledDates((disabledDates) => [
          ...disabledDates,
          moment(moment.utc(item.dateToShow).format("MM/DD/YYYY")).toDate(),
        ]);
      });
    } else {
      props.getAllArt(localStorage.getItem("token"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allArtList]);

  return (
    <>
      <Header />
      <Divider />
      <ArtForm onSubmit={submitForm}>
        <ArtArea>
          {!file.preview ? (
            <DropArea {...getRootProps()}>
              <DropAreaText>
                Drag 'n' drop some files here, or click to select files
              </DropAreaText>
            </DropArea>
          ) : null}
          <input id="image" type="text" name="image" {...getInputProps()} />
          <ReactCrop
            src={file.preview}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <FormError>
            {art.artTitle.length > 255 ? `Title too long` : null}
          </FormError>
          {completedCrop ? (
            <>
              <ArtPreview>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                  }}
                />

                <ArtContent>
                  <Artist>{art.artistName}</Artist>
                  <Title>{art.artTitle}</Title>
                  <DateToShow>
                    {art.dateToShow
                      ? moment(art.dateToShow, "YYYY-MM-DD").format(
                          "MM-DD-YYYY"
                        )
                      : ""}
                  </DateToShow>
                  <Description>{art.artDescription}</Description>
                </ArtContent>
              </ArtPreview>
              <SubmitButton type="button" onClick={() => imageReset()}>
                Reset
              </SubmitButton>
              <SubmitButton
                type="button"
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() =>
                  generateDownload(
                    previewCanvasRef.current,
                    completedCrop,
                    fileDetails.mimeType
                  )
                }
              >
                Download cropped image
              </SubmitButton>
            </>
          ) : null}
        </ArtArea>

        <FormFields>
          <FormLabel htmlFor="dateToShow">Date to Show</FormLabel>
          <DatePickerWrapper>
            <DayPickerInput
              onDayChange={(day) =>
                setArt({
                  ...art,
                  dateToShow: `${formatDate(day, "YYYY-MM-DD")}T00:00:00Z`,
                })
              }
              dayPickerProps={{
                todayButton: "Go To Today",
                disabledDays: disabledDates,
              }}
              formatDate={formatDate}
              parseDate={parseDate}
              value={query.get("date")}
              placeholder={
                query.get("date") && query.get("date").length > 0
                  ? query.get("date")
                  : formatDate(new Date())
              }
            />
            <DateError>{dateError}</DateError>
          </DatePickerWrapper>

          <FormLabel htmlFor="artTitle">Art Title</FormLabel>
          <FormInput
            required
            id="artTitle"
            type="text"
            name="artTitle"
            onChange={handleChanges}
            value={art.artTitle}
          />
          <FormError>
            {art.artTitle.length > 255 ? `Title too long` : null}
          </FormError>

          <FormLabel htmlFor="artistName">Image Artist</FormLabel>
          <FormInput
            required
            id="artistName"
            type="text"
            name="artistName"
            onChange={handleChanges}
            value={art.artistName}
          />
          <FormError>
            {art.artistName.length > 255 ? `Name too long` : null}
          </FormError>

          <FormLabel htmlFor="dateArtCreated">Date Art Created</FormLabel>
          <FormInput
            id="dateArtCreated"
            type="text"
            name="dateArtCreated"
            onChange={handleChanges}
            value={art.dateArtCreated}
          />

          <FormLabel htmlFor="artDescription">Image Description</FormLabel>
          <FormInput
            id="artDescription"
            type="text"
            name="artDescription"
            onChange={handleChanges}
            value={art.artDescription}
          />
          <FormError>
            {art.artDescription.length > 255 ? `Description too long` : null}
          </FormError>

          <FormLabel htmlFor="externalLink">External Link</FormLabel>
          <FormInput
            id="externalLink"
            type="text"
            name="externalLink"
            onChange={handleChanges}
            value={art.externalLink}
          />
          {props.postArtStart ? (
            <SubmitButton type="submit" disabled>
              Submitting...
            </SubmitButton>
          ) : (
            <>
              <SubmitButton type="submit">Submit</SubmitButton>
              {!s3Files.cropped || !s3Files.thumbnail || !s3Files.original ? (
                <FormError>
                  {!s3Files.cropped ? "Crop " : null}
                  {!s3Files.thumbnail ? "/ Thumbnail " : null}
                  {!s3Files.thumbnail ? "/ Image " : null}missing, don't submit!
                </FormError>
              ) : null}

              <ul>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.museodelprado.es/en"
                  >
                    The Prado
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://arthistoryproject.com/"
                  >
                    Obelisk Art History Project
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://artvee.com/"
                  >
                    Artvee
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://artsandculture.google.com/explore"
                  >
                    Google Arts and Culture
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.nasjonalmuseet.no/en/"
                  >
                    National Museum (Oslo, Norway)
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://collections.louvre.fr/en/"
                  >
                    Louvre Collections
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.rct.uk/collection/search#/page/1#what"
                  >
                    Royal Collection UK
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.metmuseum.org/art/the-collection"
                  >
                    The Met
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://whitney.org/collection/works?q%5Bclassification_cont%5D=Paintings&q%5Bhas_image_true%5D=1&q%5Bs%5D=sort_date%20asc"
                  >
                    The Whitney
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.rijksmuseum.nl/en/rijksstudio"
                  >
                    Rijks Museum
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.uffizi.it/en/artworks"
                  >
                    Uffizi
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.museothyssen.org/en/collection/masterpieces"
                  >
                    Thyssen Bornemisza
                  </a>
                </li>
              </ul>
            </>
          )}
          {props.postArtError && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              Welp, looks like it's on fire again
            </p>
          )}
        </FormFields>
      </ArtForm>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
  postArtError: state.postArtError,
  postArtStart: state.postArtStart,
  getArtStart: state.getArtStart,
  getArtSuccess: state.getArtSuccess,
  allArtList: state.allArtList,
});

export default connect(mapStateToProps, { postArt, getAllArt })(
  withRouter(AddArt)
);
