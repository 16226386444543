import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useParams, useLocation } from "react-router-dom";
import ArtPiece from "./ArtPiece";
import { getArtByDay, deleteArt, postArt, getAllArt } from "../store/actions";
import Header from "./Header";
import Footer from "./Footer";
import style from "styled-components";
import moment from "moment";
import AnimatedLoading from "./AnimatedLoading";
import { SquareButton } from "./Buttons";
import DayPickerInput from "react-day-picker/DayPickerInput";

// eslint-disable-next-line
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import axios from "axios";

const Loading = style.div`
  margin: 200px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArtOfTheDay = style.div`
  flex: 1;
`;

const CroppedImage = style.img`
  width: 50%;
`;

const DeleteButton = style.button`
  background-color: #000;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  font-weight: 800;
  cursor: pointer;
  &:disabled {
    opacity: .3;
  }
  &:hover {
    background-color: #F14946;
  }
`;

const ButtonCollection = style.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Flex = style.div`
  display: flex;
`;

const Repost = style(SquareButton)`
  margin-right: 10px;
`;

const DateError = style.p`
  color: #E31A16;
  font-weight: 600;
  font-size: 14px;
  margin: 0 auto;
`;

const DatePickerWrapper = style.div`
  display: flex;
  &&& {
    input {
      border: none;
      outline: none;
      border-bottom: 2px solid #000;
      padding: 10px 0;
      width: 100%;
    }

    .DayPicker-TodayButton {
      width: 100%;
      text-align: center;
    }

    .DayPicker-Day--disabled {
      color: #dce0e0;
      cursor: default;
    }
`;

const ArtByDate = ({
  getArtByDayStart,
  getArtByDay,
  getArtByDaySuccess,
  allArtList,
  artOfTheDay,
  getAllArt,
  deleteArt,
  postArt,
  history,
}) => {
  let { date } = useParams();
  if (date) date = moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (typeof date === "undefined") {
      getArtByDay(today);
    } else {
      getArtByDay(date);
    }
  }, [date, today, getArtByDay]);

  // dateToShow: `${formatDate(day, "YYYY-MM-DD")}T00:00:00Z`,

  const [art, setArt] = useState({});
  const [cropped, setCropped] = useState("");

  const [dateError, setDateError] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  useEffect(() => {
    if (artOfTheDay) {
      setArt({
        ...art,
        artistName: artOfTheDay.artistName,
        artTitle: artOfTheDay.artTitle,
        dateArtCreated: artOfTheDay.dateArtCreated,
        dateToShow:
          query.get("date") && query.get("date").length > 0
            ? moment(query.get("date")).format("YYYY-MM-DD")
            : "",
        artDescription: artOfTheDay.artDescription,
        externalLink: artOfTheDay.externalLink,
        imageUrl: artOfTheDay.imageUrl,
        user_id: parseInt(localStorage.getItem("user_id")),
      });

      setCropped(artOfTheDay.imageUrl.replace("original", "cropped"));

      console.log(artOfTheDay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artOfTheDay]);

  useEffect(() => {
    // console.log(`${process.env.REACT_APP_API}/art/${art.dateToShow}`);
    if (art.dateToShow) {
      axios
        .get(`${process.env.REACT_APP_API}/art/${art.dateToShow}`)
        .then((res) => {
          console.log(`date is taken, ${res.data}`);
          setDateError("Date is Taken, try another!");
        })
        .catch((err) => {
          setDateError("");
        });
    }
  }, [art.dateToShow]);

  // let disabledDates = [];

  const [disabledDates, setDisabledDates] = useState([]);

  useEffect(() => {
    if (allArtList && allArtList.length) {
      allArtList.map((item) => {
        return setDisabledDates((disabledDates) => [
          ...disabledDates,
          moment(moment.utc(item.dateToShow).format("MM/DD/YYYY")).toDate(),
        ]);
      });
    } else {
      getAllArt(localStorage.getItem("token"));
    }
  }, [getAllArt, allArtList]);

  return (
    <>
      <Header />
      <ArtOfTheDay>
        {getArtByDayStart ? (
          <>
            <Loading>
              <AnimatedLoading />
            </Loading>
          </>
        ) : (
          <>
            {getArtByDaySuccess ? (
              <>
                <ArtPiece
                  key={artOfTheDay.id}
                  imageUrl={artOfTheDay.imageUrl}
                  artistName={artOfTheDay.artistName}
                  artTitle={artOfTheDay.artTitle}
                  dateToShow={artOfTheDay.dateToShow}
                  artDescription={artOfTheDay.artDescription}
                  externalLink={artOfTheDay.externalLink}
                  today={today ? true : false}
                />
                {cropped && <CroppedImage src={cropped} />}

                {localStorage.getItem("token") &&
                  localStorage.getItem("token").length > 0 && (
                    <>
                      <ButtonCollection>
                        <DeleteButton
                          onClick={() => {
                            deleteArt(
                              localStorage.getItem("token"),
                              artOfTheDay.id,
                              history
                            );
                          }}
                        >
                          Delete
                        </DeleteButton>
                        <Flex>
                          <Repost
                            disabled={art.dateToShow ? false : true}
                            onClick={() =>
                              postArt(
                                localStorage.getItem("token"),
                                art,
                                history
                              )
                            }
                          >
                            Repost
                          </Repost>
                          <DatePickerWrapper>
                            <DayPickerInput
                              onDayChange={(day) =>
                                setArt({
                                  ...art,
                                  dateToShow: `${formatDate(
                                    day,
                                    "YYYY-MM-DD"
                                  )}T00:00:00Z`,
                                })
                              }
                              dayPickerProps={{
                                todayButton: "Go To Today",
                                disabledDays: disabledDates,
                              }}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              value={query.get("date")}
                              placeholder={
                                query.get("date") &&
                                query.get("date").length > 0
                                  ? query.get("date")
                                  : formatDate(new Date())
                              }
                            />
                            <DateError>{dateError}</DateError>
                          </DatePickerWrapper>
                        </Flex>
                      </ButtonCollection>
                    </>
                  )}
              </>
            ) : (
              <>
                <Loading>
                  <AnimatedLoading />
                </Loading>
              </>
            )}
          </>
        )}
      </ArtOfTheDay>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  artOfTheDay: state.artOfTheDay,
  getArtByDayStart: state.getArtByDayStart,
  getArtByDaySuccess: state.getArtByDaySuccess,
  loginSuccess: state.loginSuccess,
  isLoggedIn: state.isLoggedIn,
  token: state.token,
  postArtError: state.postArtError,
  postArtStart: state.postArtStart,
  getArtStart: state.getArtStart,
  getArtSuccess: state.getArtSuccess,
  allArtList: state.allArtList,
});

export default connect(mapStateToProps, {
  getArtByDay,
  deleteArt,
  postArt,
  getAllArt,
})(withRouter(ArtByDate));
