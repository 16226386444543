import React from "react";
import style, { keyframes } from "styled-components";

const dot = keyframes`
    0%,50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
`;

const Ellipsis = style.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
`;

const AnimatedEllipsisOne = style.span`
  width: 10px;
  opacity: 0;
  animation: ${dot} 1.3s infinite;
  animation-delay: 0ms
`;

const AnimatedEllipsisTwo = style.span`
  width: 10px;
  opacity: 0;
  animation: ${dot} 1.3s infinite;
  animation-delay: .2s
`;

const AnimatedEllipsisThree = style.span`
  width: 10px;
  opacity: 0;
  animation: ${dot} 1.3s infinite;
  animation-delay: .3s
`;

const AnimatedLoading = (props) => {
  return (
    <>
      <Ellipsis>
        <AnimatedEllipsisOne>.</AnimatedEllipsisOne>
        <AnimatedEllipsisTwo>.</AnimatedEllipsisTwo>
        <AnimatedEllipsisThree>.</AnimatedEllipsisThree>
      </Ellipsis>
      
    </>
  );
};

export default AnimatedLoading;