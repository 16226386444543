import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import style from "styled-components";

const AboutContent = style.div`
  flex: 1;
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #E2E2E2;
  padding: 0 100px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  h1 {
      color: #9F9F9F;
      font-size: 1.2em;
      text-align: center;
      width: 300px;
      margin: 54px auto;
      @media (max-width: 768px) {
        width: 100%;
        margin: 54px auto 20px;
      }
  }
  h3 {
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 35px;
  }
  h4 {
    font-size: 29px;
    color: #9F9F9F;
    margin-top: 0;
    width: 300px;
  }
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: unset;
    }
  }
  .block.mod-second {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  .block-image {
    width: 280px;
  }
  .block-text {
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
  .block-text.mod-align-right {
    text-align: right;
  }
  h4.mod-left, h3.mod-left, img.mod-left {
    margin-left: auto;
  }
  h4.mod-right, h3.mod-right, img.mod-right {
    margin-right: auto;
  }
  p {
    line-height: 24px;
  }
  a {
      color: #F14946;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const About = (props) => {
    return (
        <>
            <Header/>
            <AboutContent>
                <h1>A very simple desktop app that changes your wallpaper</h1>
                <div className="block">
                  <div className="block-text">
                    <h3>Exposure to Art</h3>
                    <h4>Just one piece of art per day</h4>
                  </div>
                  <img className="block-image mod-left" alt="Lady with a parasol" src={process.env.PUBLIC_URL + "/lady-with-a-parasol.jpg"} />
                </div>
                <div className="block mod-second">
                  <img className="block-image mod-right" alt="Lady with a parasol" src={process.env.PUBLIC_URL + "/chat@2x.png"} />
                  <div className="block-text mod-align-right">
                    <h3 className="mod-left">Shared Experience</h3>
                    <h4 className="mod-left">Everyone sees the same art</h4>
                  </div>
                </div>

                <p>Art Diario is my way of both exposing people to art, and forcing myself to become more literate in the art world. So much incredible art has been created, but our means of accessing it are either extremely ephemeral or geographically bound. You scroll past it on instagram, or you go to a museum.</p>
                <p>I wanted to create something that forced people to marinate in a single piece for 24 hours. I wanted that experience to be shared, e.g. everyone for that day saw the same thing. So I made a desktop app where you don't do anything, you just let it show you a new piece of art every day, and if you want to know more about it, you can.</p>
                <p>My hope is that this will allow people to passively appreciate art they never would have otherwise been exposed to, and to open up the opportunity to talk about the shared experience of that art.</p>
                <p>It's a pretty simple idea, but I think it's super rad. Download it already!</p>
                <p>Love, Jimmy Hooker</p>
                <p>P.S.: If you want to hear more about how I built this, I detail it on <a href="https://www.jimmyhooker.com">my website</a>.</p>
            </AboutContent>
            
            <Footer/>
        </>
    );
};

export default About;