import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getArt } from "../store/actions";
import Header from "./Header";
import Footer from "./Footer";
import ArtPiece from "./ArtPiece";
import AnimatedLoading from "./AnimatedLoading";
import style from "styled-components";
import Masonry from "react-masonry-css";
import moment from "moment";
import { SquareButton } from "./Buttons";

const Loading = style.div`
  margin: 200px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArtContent = style.div`
  flex: 1;
`;

const PrevNext = style.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const MissingArt = style(Link)`
  border: 2px dotted #ECEAEA;
  border-radius: 10px;
  margin: 30px 0;
  padding: 40px 10px 20px;
  display: block;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  text-align: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: all .2s ease-in-out;
  &:hover {
    border: 2px solid #F14946;
    color: #F14946;
  }
`;

const DayOfWeek = style.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Weekend = style.div`
  background: #000;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px 0;
`;

const Weekday = style.div`
  background: #cfcfcf;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px 0;
`;

const Date = style.div`
  padding: 10px 0;
`;

const ArtLink = style(Link)`
  text-decoration: none;
  transition: all .2s ease-in-out;
  &:hover {
  
  }
`;

const Art = ({ postArtSuccess, getArt, getArtStart, artList }) => {
  let dates = [];
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    const currDate = moment(startDate).startOf("day");
    const lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      // console.log(currDate.toDate());
      dates.push(currDate.clone().toDate());
    }

    return dates;
  };

  // const today = moment().subtract(1, "days").format("YYYY-MM-DD");
  // const twoMonths = moment().add(60, "days").format("YYYY-MM-DD");

  const [timespan, setTimespan] = useState({
    today: moment().subtract(1, "days").format("YYYY-MM-DD"),
    twoMonths: moment().add(60, "days").format("YYYY-MM-DD"),
  });

  const previous60 = () => {
    setTimespan({
      ...timespan,
      today: moment(timespan.today).subtract(60, "days").format("YYYY-MM-DD"),
      twoMonths: moment(timespan.twoMonths)
        .subtract(60, "days")
        .format("YYYY-MM-DD"),
    });
  };

  const next60 = () => {
    setTimespan({
      ...timespan,
      today: moment(timespan.today).add(60, "days").format("YYYY-MM-DD"),
      twoMonths: moment(timespan.twoMonths)
        .add(60, "days")
        .format("YYYY-MM-DD"),
    });
  };

  enumerateDaysBetweenDates(timespan.today, timespan.twoMonths);

  useEffect(() => {
    getArt(localStorage.getItem("token"), timespan.today);
  }, [postArtSuccess, getArt, timespan.today]);

  return (
    <>
      <Header />
      <ArtContent>
        {getArtStart ? (
          <>
            <Loading>
              <AnimatedLoading />
            </Loading>
          </>
        ) : (
          <>
            {artList && artList.length ? (
              // iterate over the next 60 days
              <>
                <PrevNext>
                  <SquareButton onClick={previous60}>Previous</SquareButton>
                  <SquareButton onClick={next60}>Next</SquareButton>
                </PrevNext>
                <Masonry
                  breakpointCols={{ default: 7, 800: 2 }}
                  className="art-grid"
                  columnClassName="art-column"
                >
                  {
                    // eslint-disable-next-line
                    dates.map((date, index) => {
                      // return art that matches the date
                      let returnedArt = artList.filter((art) => {
                        if (
                          moment.utc(art.dateToShow).format("YYYY-MM-DD") ===
                          moment.utc(date).format("YYYY-MM-DD")
                        ) {
                          return art;
                        } else {
                          return false;
                        }
                      });

                      // if there is art, return it, otherwise return an empty placeholder
                      if (
                        returnedArt[0] &&
                        returnedArt[0].dateToShow.length > 0
                      ) {
                        if (
                          moment
                            .utc(returnedArt[0].dateToShow)
                            .format("YYYY-MM-DD") ===
                          moment.utc(date).format("YYYY-MM-DD")
                        ) {
                          return (
                            <ArtLink
                              key={index}
                              to={`/art/date/${moment
                                .utc(returnedArt[0].dateToShow)
                                .format("MM-DD-YYYY")}`}
                            >
                              <ArtPiece
                                artPage
                                imageUrl={returnedArt[0].imageUrl.replace(
                                  "original",
                                  "thumbnail"
                                )}
                                artistName={returnedArt[0].artistName}
                                artTitle={returnedArt[0].artTitle}
                                dateToShow={returnedArt[0].dateToShow}
                                artDescription={returnedArt[0].artDescription}
                                showLink={false}
                              />
                            </ArtLink>
                          );
                        }
                      } else {
                        return (
                          <MissingArt
                            key={index}
                            to={`/art/add?date=${moment
                              .utc(date)
                              .format("MM/DD/YYYY")}`}
                          >
                            <DayOfWeek>
                              {moment.utc(date).day() === 6 ||
                              moment.utc(date).day() === 0 ? (
                                <Weekend>
                                  {moment.utc(date).format("dddd")}
                                </Weekend>
                              ) : (
                                <Weekday>
                                  {moment.utc(date).format("dddd")}
                                </Weekday>
                              )}
                            </DayOfWeek>
                            <div>No art for</div>
                            <Date>
                              {`${moment.utc(date).format("MM-DD-YYYY")}`}
                            </Date>
                          </MissingArt>
                        );
                      }
                    })
                  }
                </Masonry>
              </>
            ) : (
              console.log("No art yet")
            )}
          </>
        )}
      </ArtContent>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
  getArtStart: state.getArtStart,
  getArtSuccess: state.getArtSuccess,
  artList: state.artList,
});

export default connect(mapStateToProps, {
  getArt,
})(withRouter(Art));
